import { ItemStatuses } from 'constants/statuses';
import { DataForCards } from 'flows/core/hooks/use-data-for-cards';
import {
  BadgeConfig,
  CardData,
  CardDataWithHeroExtras,
} from 'flows/core/types/cards';
import { EssentialCards } from 'flows/core/types/layouts';
import { CLOUDINARY_BASE_URL } from 'flows/insurance/constants';
import { getProviderData } from 'flows/insurance/constants/providers';
import merge from 'lodash/merge';
import { ItemKinds } from 'types';
import { Badges, enabledItemDefOfKind, isCompleted, itemOfKind } from './utils';

const KIND = ItemKinds.HOMEOWNER_INSURANCE;

const INSURANCE_COMMON_STATIC_DATA = {
  subtype: 'SYI',
  icon: 'SHIELD',
  title: 'Secure home insurance',
  action: {
    type: 'internal',
    route: '/insurance/homeowner',
  },
} as const;

const INSURANCE_INITIATE_STATIC_DATA = {
  ...INSURANCE_COMMON_STATIC_DATA,
  description: 'Purchase protection for your new home.',
} as const;

export const statusToBadge = (
  status?: string,
  tab?: string
): BadgeConfig | undefined => {
  if (status === ItemStatuses.STARTED && tab !== 'today') {
    return Badges.RECENTLY_VIEWED;
  }
  switch (status) {
    case ItemStatuses.UNVERIFIED:
      return Badges.ALMOST_DONE;
    case ItemStatuses.VALIDATING:
      return Badges.IN_REVIEW;
    case ItemStatuses.COMPLETED:
      return Badges.DONE;
    case ItemStatuses.INFORMATION_REQUESTED:
    case ItemStatuses.INFO_REQUESTED:
      return Badges.NEEDS_MORE;
    default:
      return undefined;
  }
};

export const statusToImportance = (status?: string) => {
  if (status === 'started') {
    return 750_001;
  }
  if (status === 'completed') {
    return 0;
  }
  return 1_000_000;
};

export const makeCardJson = (
  status: string,
  commonData: any,
  payloadKind: 'INITIATE' | 'RECEIPT' | 'PROMOTED'
): CardDataWithHeroExtras => {
  return {
    type: payloadKind,
    data: {
      ...commonData,
      badge: statusToBadge(status, 'today'),
    },
    heroExtras: {
      heading: 'Get insurance coverage to keep your stuff safe. ',
      ctaText: 'Get started',
    },
    identifier: `syi_${payloadKind.toLowerCase()}`,
    importanceScore: statusToImportance(status),
  };
};

export const makeTodayTabHomeownersInsuranceCards = ({
  itemDefinitions,
  items,
  user,
}: DataForCards): CardDataWithHeroExtras[] => {
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const insuranceItems = items.filter(itemOfKind(KIND));
  const isOwner = user?.currentMove?.toAddress.ownership === 'OWN';
  if (!isOwner || !itemDef?.page) return [];
  if (itemDef) {
    const { status, route } = {
      status: insuranceItems[0]?.status,
      route: 'insurance/homeowner',
    };

    const recommendedBy = itemDef.entity?.name?.split(' ').shift();
    const preferredProviderText = `Recommended by ${recommendedBy}`;

    const cardJson = makeCardJson(
      status,
      {
        ...INSURANCE_COMMON_STATIC_DATA,
        action: {
          ...INSURANCE_COMMON_STATIC_DATA.action,
          route,
        },
      },
      'PROMOTED'
    );

    const providerData = getProviderData(itemDef.page?.name) || {
      ...itemDef.page,
      logo: itemDef.page.logo_id
        ? `${CLOUDINARY_BASE_URL}${encodeURI(itemDef.page.logo_id)}`
        : null,
    };
    const preferredProviderJson = merge(cardJson, {
      data: {
        image: providerData?.logo
          ? { url: providerData.logo, alt: providerData.name }
          : {},
        description: preferredProviderText,
      },
    });
    return [preferredProviderJson];
  }

  return [];
};

export const makeEssentialsTabHomeownersInsuranceCards = ({
  itemDefinitions,
  items,
  user,
}: DataForCards): EssentialCards => {
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const insuranceItems = items.filter(itemOfKind(KIND));
  const isOwner = user?.currentMove?.toAddress.ownership === 'OWN';

  const proofOfInsuranceItemDef = itemDefinitions.find(
    enabledItemDefOfKind(ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_INSURANCE)
  );

  const toDoCards: CardData[] = [];
  const completedCards: CardData[] = [];
  if (itemDef && Boolean(itemDef.page) && isOwner) {
    const { status, route } = {
      status: insuranceItems[0]?.status,
      route: 'insurance/homeowner',
    };

    const insuranceStaticData = {
      ...INSURANCE_INITIATE_STATIC_DATA,
      action: {
        ...INSURANCE_INITIATE_STATIC_DATA.action,
        route,
      },
    };

    if (insuranceItems.length === 0) {
      toDoCards.push({
        type: 'INITIATE',
        data: {
          ...insuranceStaticData,
          badge: proofOfInsuranceItemDef ? statusToBadge(status) : undefined,
        },
        identifier: 'syi_initiate',
        importanceScore: 1_000_000,
      });
    } else {
      const insuranceItem = insuranceItems[0];
      if (isCompleted({ status })) {
        completedCards.push({
          type: 'INITIATE',
          data: {
            ...insuranceStaticData,
            badge: statusToBadge(status),
          },
          identifier: `syi_initiate_completed_${insuranceItem.id}`,
          importanceScore: 1_000_000,
        });
      } else {
        toDoCards.push({
          type: 'INITIATE',
          data: {
            ...insuranceStaticData,
            // TODO: add badge logic back when we decide on implementation for ROB + Assurant
            badge: proofOfInsuranceItemDef ? statusToBadge(status) : undefined,
          },
          identifier: `syi_initiate_${insuranceItem.id}`,
          importanceScore: 1_000_000,
        });
      }
    }
  }
  return {
    toDoCards,
    completedCards,
  };
};
